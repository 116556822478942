.bg-grey-dark {
  background-color: #2C2E31 !important;
}

.bg-grey {
  background-color: #333537 !important;
}

.bg-grey-light {
  background-color: #37393C !important;
}

.bg-primary {
  background-color: #3E526F !important;
}

.h-100vh {
  height: 100vh !important;
}

.w-40 {
  width: 40% !important;
}

.dropdown-toggle::after {
  display: none !important; 
}

.dropdown-item:active {
  background-color: #3E526F !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: rgba(182, 107, 56, 0.2);
}

.dropdown-item:active {
  background-color: rgba(182, 107, 56, 0.6) !important;
}